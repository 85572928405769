import Vue from 'vue'
// import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import BootsrapVue from 'bootstrap-vue';
import moment from 'moment';
import TerminologyService from '@/services/TerminologyService';
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en.json';
import VueGapi from 'vue-gapi';
import VueApexCharts from 'vue-apexcharts';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import VAutocomplete from 'v-autocomplete'
// Install VeeValidate components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
// Add localisation which provides the messages for default vee-validate.
localize({en});


// VueValidate custom validations
extend('lettersOrSpecial', {
  validate(value) {
    const pattern = /[a-zA-Z.!#$%&’*+/=?^_`{|}~-]/i
    return pattern.test(value);
  },
  message: 'This field must contain at least one letter or special character',
});

extend('allowedRangeAsciiPrintable', {
  validate(value) {
    const pattern = /^[a-z0-9!"#$%&'()*+,.\/:;<=>?@\[\] ^_`{|}~-]*$/i
    return pattern.test(value);
  },
  message: 'This field contains an invalid character',
});

extend('someNumeric', {
  validate: value => {
    return /[0-9]/.test(value);
  },
  message: 'The field must contain at least one number'
})

extend('strongPasswordSequence', {
  validate: value => {
    const repeatCharPattern: RegExp = /(\D)\1{2,}/;
    const repeatDigitPattern: RegExp = /(\d)\1{4,}/;

    // repeated character valid
    return !repeatCharPattern.test(value) && !repeatDigitPattern.test(value)
  },
  message: 'The field must not contain repeated characters (max 4 repeated digits and 2 repeated letters/symbols)'
})

extend('strongPasswordPattern', {
  validate: value => {
    const sequentialLetterPattern = /(abcde|bcdef|cdefg|defgh|efghi|fghij|ghijk|hijkl|ijklm|jklmn|klmno|lmnop|mnopq|nopqr|opqrs|pqrst|qrstuv|rstuvw|stuvwx|tuvwxy|uvwxyz|edcba|fedcb|gfedc|hgfed|ihgfe|jihgf|kjihg|lkjih|mlkji|nmlkj|onmlk|ponml|qponm|rqpon|srqpo|tsrqp|vutsrq|wvutsr|xwvuts|yxwvut|zyxwvu)/i;
    const sequentialDigitPattern = /(01234|12345|23456|34567|45678|56789|43210|54321|65432|76543|87654|98765)/

    // character/digit sequences valid
    return !sequentialLetterPattern.test(value) && !sequentialDigitPattern.test(value)
  },
  message: 'The field must not contain sequences of characters, max of 4 allowed'
})

extend('appPackageNameValid', {
  validate: value => {
    if (value.startsWith("madepurpleos.com")) {
      return true
    }
    const lettersDigitsDotsOnly = /^[a-zA-Z0-9.]+$/;

    // letters/digits/dots valid
    return lettersDigitsDotsOnly.test(value)
  },
  message: "The field must not contain special characters apart from '.' (except for madepurpleos.com packages)"
})

extend('match', {
  validate(value, match) {
    return value === match;
  },
  message: 'This field must match the above',
  params: ['match'],
});

extend('after_or_equal_date', {
  validate(value, params) {
    interface DateParams {
      startDate: string;
    }
    const dateParams = params as DateParams;
    return new Date(value) >= new Date(dateParams.startDate);
  },
  params: ['startDate'],
  message: 'The end date must be after or equal to the start date.'
});

extend('after_or_equal_time', {
  validate(value, params) {
    interface TimeParams {
      startTime: string;
      startDate: string;
      endDate: string;
    }
    const timeDateParams = params as TimeParams;
    const startMoment = moment(`${timeDateParams.startDate}T${timeDateParams.startTime}`);
    const endMoment = moment(`${timeDateParams.endDate}T${value}`);

    return endMoment.isSameOrAfter(startMoment);
  },
  params: ['startTime', 'startDate', 'endDate'],
  message: 'The end time must be after or equal to the start time.',
});

// extend('mixedOrNumericLimit', {
//   validate(value, { max }) {
//
//     return !(value.length > max && Number.isInteger(value));
//   },
//   message: 'This field must be not be less than { max } characters if a number',
//   params: ['max'],
// });

// BSV Plugin Registration
Vue.use(BootsrapVue)

// Apex charts
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

// Composition API
// @ts-ignore
Vue.use(VueCompositionAPI)

// v-autocomplete
Vue.use(VAutocomplete)

// Google api
Vue.use(VueGapi, {});

// CK Editor
Vue.use(CKEditor)

// Autocomplete
Vue.use(Autocomplete)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.filter('formatDate', value => {
  if (!value) return '';
  return moment(String(value)).format('DD/MM/YYYY');
});

Vue.filter('formatDateTime', value => {
  if (!value) return '';
  return moment(String(value)).format('DD/MM/YYYY HH:mm');
});

Vue.filter('formatSeconds', value => {
  if(!value) {
    return '';
  }

  const duration = moment.duration(value, 'seconds');
  if(duration.hours() > 0) {
    return `${duration.hours() < 10 ? '0': ''}${duration.hours()}:${duration.minutes() < 10 ? '0' : ''}${duration.minutes()}:${duration.seconds() < 10 ? '0': ''}${duration.seconds()} (hh:mm:ss)`
  } else if(duration.minutes() > 0) {
    return `${duration.minutes() < 10 ? '0' : ''}${duration.minutes()}:${duration.seconds() < 10 ? '0': ''}${duration.seconds()} (mm:ss)`
  } else {
    return duration.seconds() + ' secs'
  }
})

Vue.filter('round2dp', value => {
  if (!value) return '0';
  return (Math.round(value * 100) / 100).toFixed(2);
});

Vue.filter('formatDateTimeUnix', value => {
  if (!value) return '';
  const unixTime = moment.unix(value)
  return unixTime.format('DD/MM/YYYY HH:mm');
});

Vue.filter('howLongUptime', value => {
  if (!value) return '';
  const ago = moment().subtract(value, 'seconds');
  return ago.fromNow(true);
});

Vue.filter('isOnline', value => {
  if (!value) return '';
  const ago = moment().subtract(10, 'minutes');
  return moment(String(value)).isAfter(ago) ? '🟢' : '🔴';
});

Vue.filter('intToCurrency', value => {
  if (!value) return '';
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });
  return formatter.format(value / 100);
})

Vue.filter('capEachWord', value => {
  if (!value) return '';
  return value
    .toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
});

Vue.filter('boolToYes', value => (value ? 'Yes' : 'No'));

Vue.filter('isPublished', value => (value ? 'Published' : 'Draft'));

// eslint-disable-next-line consistent-return
Vue.filter('term', (word, capitalize = false) => {
  const establishment = 'prison'; // todo link up to establishment

  if (!word) return '';

  if (word === 'prison') {
    return TerminologyService.getEstablishmentTextForPrison(establishment, capitalize);
  } if (word === 'prisoner') {
    return TerminologyService.getEstablishmentTextForPrisoner(establishment, capitalize);
  }
});

Vue.filter('arrayToText', value => {
  if (!value) return '';
  let text = '';
  const newArray = JSON.parse(value);

  newArray.forEach((item, index) => {
    text += item;

    if (index < newArray.length - 1) {
      text += ', ';
    }
  });

  return text;
});

Vue.filter('stripUnderscore', value => {
  if (!value) return '';
  let text = '';
  const newArray = value.split('_');

  newArray.forEach((item, index) => {
    text += item.toLowerCase();

    if (index < newArray.length - 1) {
      text += ' ';
    }
  });

  return text;
});

Vue.filter('toGigaBytes', value => {
  if (!value) return '';
  const kb = value / 1024;
  const mb = kb / 1024;
  const gb = mb / 1024

  return gb.toFixed(2);
});

Vue.filter('str_limit', (value, size) => {
  if (!value) return '';
  const strValue = value.toString();

  if (strValue.length <= size) {
    return value;
  }
  return `${strValue.substring(0, size)}...`;
});

Vue.filter('splitCamelCase', value => {
  if(!value) return '';
  return value
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between lowercase and uppercase letters
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2'); // Add space between consecutive uppercase letters followed by a lowercase letter
})

Vue.filter('formatSnakeCase', value => {
  if(!value) return '';
  return value
    .replace(/([A-Z])/g, ' $1')
    .replace(/_/g, ' ')
    .replace(/^\w/, c => c.toUpperCase());
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

Vue.filter('formatYoutubeChannelUrl', value => {
  if (!value) return '';
  return `https://www.youtube.com/channel/${value}`;
});
Vue.filter('formatYoutubeVideoUrl', value => {
  if (!value) return '';
  return `https://www.youtube.com/watch?v=${value}`;
});
